<template>
  <div>
    <van-cell-group title="选择省">
      <van-cell title="当前省" :value="selectArea.areaName" is-link @click="popupVisible=true"></van-cell>
    </van-cell-group>

    <van-swipe v-if="agentList.length>0" :loop="false" style="margin:15px" @change="handleChange">
      <van-swipe-item v-for="(item) in agentList" :key="item.id">
        <el-card style="background-color:#409EFF">
          <van-cell title="省/直辖市" :value="item.provinceName"></van-cell>
          <van-cell title="市" :value="item.cityName"></van-cell>
          <van-cell title="地址" :value="item.address" is-link :url="'https://uri.amap.com/marker?position='+item.longitude+','+item.latitude"></van-cell>
          <van-cell title="联系人" :value="item.contactName"></van-cell>
          <van-cell title="电话"><a :href="'tel:'+item.phone">{{item.phone}}</a></van-cell>
        </el-card>
      </van-swipe-item>
    </van-swipe>
    <van-empty v-else description="暂无信息" />
    <van-popup v-model="popupVisible" position="bottom">
      <van-picker show-toolbar :columns="columnsProvince" value-key="areaName" @cancel="popupVisible=false" @confirm="handleConfirm"></van-picker>
    </van-popup>
  </div>
</template>

<script>
import Vue from "vue";
import Vant from "vant";

Vue.use(Vant);

export default {
  data() {
    return {
      popupVisible: false,
      columnsProvince: [],
      selectArea: {},
      agentList: [],
      currentIndex: 0,
    };
  },
  methods: {
    getProvince() {
      let that = this;
      that.axios.post("Area/GetProvince").then(function (response) {
        that.columnsProvince = response.data.data;
        that.handleConfirm(that.columnsProvince[2]);
      });
    },
    handleConfirm(v) {
      let that = this;
      that.selectArea = v;
      that.popupVisible = false;
      that.axios
        .post("Base_Agent/GetListByProvinceId", { onlyInt: v.id })
        .then(function (response) {
          that.agentList = response.data.data;
        });
    },
    handleChange(index) {
      let that = this;
      that.currentIndex = index;
    },
  },
  mounted() {
    let that = this;
    that.getProvince();
  },
};
</script>

<style scoped>
</style>