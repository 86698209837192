import { render, staticRenderFns } from "./WapNearbyAgent.vue?vue&type=template&id=4226c9de&scoped=true&"
import script from "./WapNearbyAgent.vue?vue&type=script&lang=js&"
export * from "./WapNearbyAgent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4226c9de",
  null
  
)

export default component.exports